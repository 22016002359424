import moment from 'moment'
import { Client, ClientDataType } from '../../models'
import { t } from 'i18next'

export const makeTableValue = (array: Client[]) => {
  let list: ClientDataType[] = []
  if (array) {
    array.forEach((e, index) => {
      list.push(makeRowParentValue(e, index))
    })
  }
  return list
}
const makeRowParentValue = (client: Client, index: number) => {
  let createAt = (client?.created_at || 0) * 1000
  let updateAt = (client?.updated_at || 0) * 1000
  let value: ClientDataType = {
    id: client?.id || '',
    key: index,
    select: true,

    nom: client.nom || '',
    code: client.code || '',
    societe: client.client_company,
    entrepot: client.warehouse_client,
    rupture: client.rupture,
    fifo: client.fifo,
    fefo: client.fefo,
    alerte_dluo: '-',
    create_at: moment(createAt).format(t('time-format')),
    update_at: moment(updateAt).format(t('time-format')),
  }

  return value
}
