import {
  Col,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
  notification,
} from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../app/store'
import CLOSE_ICON from '../../../assets/icons/close.svg'
import SEARCH_ICON from '../../../assets/icons/search.svg'
import CustomDropdown from '../../../components/Common/CustomDropdown/CustomDropdown'
import SelectRender from '../../../components/Common/DataType/SelectType'
import MultiItemSection from '../../../components/Common/MultiItemSection/MultiItemSection'
import { PagingEnum } from '../../../enum'
import useResizeScreen from '../../../hook/useResizeScreen'
import { ClientDataType } from '../../../models'
import { getAllClient } from '../../../redux/reducers/clientSlice'
import { makeTableValue } from '../../../utils/client/makeTableValue'
import NewActionRow from '../../stock/components/ActionRow/NewActionRow'
import './Client.scss'
import DUPLICATE_ICON from '../../../assets/icons/duplicate.svg'
import EDIT_ICON from '../../../assets/icons/Editor.svg'
import DELETE_ICON from '../../../assets/icons/Delete.svg'
import ERROR from '../../../assets/images/error.png'
import { ActionOnReference } from '../../../enum/ActionOnReference'
import { cloneDeep } from 'lodash'
import { handleOptions } from '../func'
import { ActionOnClient } from '../../../enum/ActionOnClient'
import clientWarehouseApi from '../../../http/clientWarehouseApi'
import { t } from 'i18next'
import CustomPopup from '../../../components/Common/CustomPopup/CustomPopup'
import { popupType } from '../../../enum/popupType'

const singleActions = ['edit']

const ClientScreen = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const pageIndex = searchParams.get('page-index')
  const pageSize = searchParams.get('page-size')
  const [count, setCount] = useState<number>(0)
  const [isShowLess, setIsShowLess] = useState<boolean>(false)
  const { currentListClient } = useSelector((state: RootState) => state.client)
  const [dataClient, setDataClient] = useState<ClientDataType[]>([])
  const dispatch = useDispatch()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  )
  const [isDeleting, setIsDeleting] = useState(false)
  const [openPopupDelete, setOpenPopupDelete] = useState(false)
  const [listOption, setListOption] = useState<any[]>([
    {
      disabled: true,
      value: 'edit',
      label: (
        <Row gutter={8} align={'middle'}>
          <Col>
            <img src={EDIT_ICON} width={15} alt="" style={{ marginTop: 5 }} />
          </Col>
          <Col>Editer</Col>
        </Row>
      ),
    },
    {
      disabled: true,
      value: 'delete',
      label: (
        <Row gutter={8} align={'middle'}>
          <Col>
            <img src={DELETE_ICON} width={15} alt="" style={{ marginTop: 5 }} />
          </Col>
          <Col className="text-[#F25524]">Supprimer</Col>
        </Row>
      ),
    },
  ])
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const columns: ColumnsType<ClientDataType> = [
    {
      title: <div className="text-center">Select</div>,
      dataIndex: 'select',
      key: 'select',
      width: 90,
      fixed: 'left',
      render: (_, row) => {
        return (
          <SelectRender
            isMainLine={true}
            isCheck={selectedRowKeys.includes(row.key)}
            onChangeChecked={(e) => {
              if (e.target.checked) {
                setSelectedRowKeys([...selectedRowKeys, row.key])
                setSelectedIds([...selectedIds, row.id])
              } else {
                setSelectedRowKeys(
                  [...selectedRowKeys].filter((i) => i !== row.key)
                )
                setSelectedIds([...selectedIds].filter((i) => i !== row.id))
              }
            }}
            screenColor="blue"
          />
        )
      },
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
      width: 520,
      render: (_, row) => {
        return <>{row.nom}</>
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 180,
      render: (_, row) => {
        return <>{row.code}</>
      },
    },
    {
      title: 'Société(s)',
      dataIndex: 'societe',
      key: 'societe',
      width: 680,
      render: (_, row) => {
        return <MultiItemSection data={row.societe || []} />
      },
    },
    {
      title: 'Entrepôt(s)',
      dataIndex: 'entrepot',
      key: 'entrepot',
      width: 680,
      render: (_, row) => {
        return <MultiItemSection data={row.entrepot || []} />
      },
    },
    {
      title: 'Rupture',
      dataIndex: 'rupture',
      key: 'rupture',
      width: 150,
      render: (_, row) => {
        return <>{row.rupture ? 'Oui' : 'Non'}</>
      },
    },
    {
      title: 'FIFO/FEFO',
      dataIndex: 'fifo',
      key: 'fifo',
      width: 150,
      render: (_, row) => {
        return <>{row.fifo ? 'FIFO' : 'FEFO'}</>
      },
    },
    {
      title: 'Alerte DLUO',
      dataIndex: 'dluo',
      key: 'dluo',
      width: 150,
      render: (_, row) => {
        return <>{row.alerte_dluo}</>
      },
    },

    {
      title: 'Mise à jour',
      dataIndex: 'updateAt',
      key: 'updateAt',
      width: 200,
      render: (_, row) => {
        return <>{row.update_at}</>
      },
    },
  ]

  const rowClassName = (record: any, index: number) => {
    return index % 2 === 0 ? 'table-row-main-even' : 'table-row-main-odd'
  }

  const onChangeOption = (value: string) => {
    setSelectedOption(value)

    switch (value) {
      case ActionOnReference.duplicate: {
        const newPath = location.pathname.split('/')
        newPath.push(selectedIds[0])
        navigate(
          `${newPath.join(
            '/'
          )}?action=duplicate&page-index=${pageIndex}&page-size=${pageSize}`
        )
        break
      }
      case ActionOnClient.edit: {
        const newPath = location.pathname.split('/')
        newPath.push(selectedIds[0])
        navigate(`${newPath.join('/')}`)
        break
      }
      case ActionOnClient.delete: {
        // setIsDeleting(true)
        // clientWarehouseApi.deleteClients(selectedIds).then(res => {
        //   setSelectedRowKeys([])
        //   setSelectedOption(undefined);
        //   setCount(count + 1);
        //   setSelectedIds([])
        // }).catch(e => {
        //   if (e.response?.data?.error?.includes("can not delete an item")) {
        //     showErrorMessage()
        //   }
        //   setSelectedOption(undefined);

        // }).finally(() => {
        //   setIsDeleting(false)

        // })

        setOpenPopupDelete(true)
        break
      }
      default:
        return
    }
  }
  useEffect(() => {
    let listOptionClone = cloneDeep(listOption)
    listOptionClone = handleOptions(selectedIds, listOptionClone, singleActions)

    setListOption(listOptionClone)
    setSelectedOption(undefined)
  }, [location.pathname])
  useEffect(() => {
    dispatch(
      getAllClient({
        limit: Number(pageSize) || 25,
        offset: (Number(pageIndex) - 1) * Number(pageSize) || 0,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize, count])

  useEffect(() => {
    const myClientList = makeTableValue(currentListClient.clientList)
    setDataClient(myClientList)
  }, [currentListClient, pageIndex, pageSize])

  const onOpenModal = () => {
    navigate(
      `/configurations/client/client-create?page-index=${pageIndex}&page-size=${pageSize}`
    )
  }

  let height = useResizeScreen({
    pixels: 14,
  })

  useEffect(() => {
    let listOptionClone = cloneDeep(listOption)

    listOptionClone = handleOptions(selectedIds, listOptionClone, singleActions)

    setListOption(listOptionClone)
    setSelectedOption(undefined)
  }, [selectedIds])

  function onDeleteClient() {
    setOpenPopupDelete(false)
    setIsDeleting(true)
    clientWarehouseApi
      .deleteClients(selectedIds)
      .then((res) => {
        setSelectedRowKeys([])
        setSelectedOption(undefined)
        setCount(count + 1)
        setSelectedIds([])
      })
      .catch((e) => {
        if (e.response?.data?.error?.includes('can not delete an item')) {
          showErrorMessage()
        }
        setSelectedOption(undefined)
      })
      .finally(() => {
        setIsDeleting(false)
      })
  }

  return (
    <div className="config-table-style">
      <div className="relative text-xs">
        <div
          className="research-bar bg-[#EEEEEE] mx-5 px-5 py-2 flex flex-row justify-between items-center"
          style={{
            borderBottomRightRadius: 7,
            borderBottomLeftRadius: 7,
          }}
        >
          <div className="grid grid-cols-7 gap-x-4 w-full">
            <Form.Item noStyle label="" name="" colon={false}>
              <Input placeholder="Nom Client" className="research-bar-input" />
            </Form.Item>
            <Form.Item noStyle label="" name="" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Toutes Sociétés"
                className="research-bar-input"
              />
            </Form.Item>
            <Form.Item noStyle label="" name="" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Tous Entrepôts"
                className="research-bar-input"
              />
            </Form.Item>
            <div className="flex flex-row gap-x-2">
              <img src={SEARCH_ICON} alt="masques" />
              <img src={CLOSE_ICON} alt="masques" />
            </div>
          </div>
        </div>
      </div>
      <div className="px-10 flex items-center justify-between">
        <div className="py-2 page-title-heading-1">CLIENTS</div>
        <div className="flex items-center">
          {selectedRowKeys.length >= 1 && (
            <Select
              value={selectedOption}
              style={{ width: 165, marginRight: -40 }}
              options={listOption}
              onChange={onChangeOption}
              placeholder={<span className="italic capitalize">Actions</span>}
            />
          )}
          <NewActionRow
            onClickPlus={onOpenModal}
            isClickUp={false}
            isHideExport
            isDisableActionButton={false}
          />
        </div>
      </div>

      <div className="pl-10">
        <Table
          style={height}
          className="config-table config-table-wrapper overflow-y-auto relative text-[#EBEBEB]"
          columns={columns}
          pagination={false}
          indentSize={0}
          loading={currentListClient.loading || isDeleting}
          dataSource={dataClient}
          rowClassName={rowClassName}
        />
      </div>
      <Pagination
        className="custom-pagination reference-pagination table-wrapper"
        style={{ margin: '20px auto' }}
        locale={{ items_per_page: '' }}
        total={currentListClient.metadata?.total || 0}
        defaultPageSize={PagingEnum.DEFAULT_PAGE_SIZE}
        showLessItems={isShowLess}
        pageSizeOptions={[
          PagingEnum.DEFAULT_PAGE_SIZE,
          PagingEnum.PAGE_SIZE_1,
          PagingEnum.PAGE_SIZE_2,
        ]}
        pageSize={Number(pageSize)}
        current={Number(pageIndex)}
        showSizeChanger
        showTotal={(total, range) =>
          `${range[0] >= 0 ? range[0] : 0}-${
            range[1] >= 0 ? range[1] : 0
          } sur ${total}`
        }
        onChange={(page, size) => {
          navigate(
            `/configurations/client?page-index=${page}&page-size=${size}`
          )
          setIsShowLess(() => {
            return page > 4
          })
        }}
      />

      <CustomPopup
        title={t('confirmDelete')}
        type={popupType.DELETE}
        isOpen={openPopupDelete}
        onConfirm={onDeleteClient}
        onClose={() => {
          setOpenPopupDelete(false)
          setSelectedOption(undefined)
        }}
      >
        <p className="text-lg text-[#505050] my-10">{`${t('sureToDelete')} ${t(
          'client'
        )}?`}</p>
      </CustomPopup>
    </div>
  )
}

const showErrorMessage = () => {
  notification.open({
    className: 'noti noti-error',
    message: (
      <div className="flex items-center">
        <img src={ERROR} alt="error" width={50} className="mr-2.5" />
        {t('clientOnOperationError')}
      </div>
    ),
    placement: 'topRight',
    closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
    duration: 3,
  })
}
export default ClientScreen
